<template>
	<div class="modal">
		<div class="empty" @click="$emit('close')"></div>
		<div class="box">
			<div class="top">
				<div class="title">Reportar locutor</div>
				<div class="close" @click="$emit('close')"><i class="fa-solid fa-xmark"></i></div>
			</div>
			<div class="content">
				<form @submit.prevent="setRep">
					<span>Anônimo?</span>
					<input 	class="slider-input" type="range" min="0" max="1" v-model="bool">
					<span class="bool">{{ bool == 1 ? 'Sim' : 'Nào' }}</span>
					<span>Descreva</span>
					<textarea placeholder="Escreva com poucas palavras o que está acontecendo." v-model="text"></textarea>
					<button>Enviar</button>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import { base } from '../../../var.js'
import axios from 'axios'

export default {
	name: 'ModReport',
	data(){
		return {
			text: '',
			bool: 0
		}
	},
	methods: {
		async setRep(){
			const req = await axios.post(base+'content/radio/report', {
				motive: this.text,
				a: this.bool
			})
			this.$store.commit('Alert', req.data.text)
		},
	}
}
</script>

<style scoped>
.modal .top {height: 50px;}
.modal textarea {height: 120px !important;}

.modal .box .content span {
	float: left;
	width: 100%;
	height: 20px;
	font-size: 14px
}

.modal .box .content input {
	float: left;
	width: 40px;
	height: 20px;
	padding: 0 !important;
}

.bool {font-size: 12px; margin: 2px 0 8px 0;}

.slider-input {
    appearance: none;
    position: relative;
    vertical-align: middle;
    border-radius: 20px;
    background: hsl(0,0%,80%);
    box-shadow: 0 1px 0 hsla(0,0%,100%,.6);
    overflow: hidden;
}

.slider-input::-webkit-slider-thumb,
.slider-input::slider-thumb {
    appearance: none;
    border-radius: 20px;
    width: 20px;
    height: 20px;
    border: 5px solid hsl(0,0%,80%);
    cursor: pointer;
    background: hsl(0,0%,40%);
    box-shadow: inset 0 1px 2px hsla(0,0%,0%,.4);
    transition: border-width .2s cubic-bezier(.26, .08, .15, 1);
}

.slider-input:active::-webkit-slider-thumb,
.slider-input:active::slider-thumb {
    border: 0px solid hsl(0,0%,80%);
    transition-duration: .1;
}


</style>