<template>
	<div class="modal">
		<div class="empty" @click="$emit('close')"></div>
		<div class="box">
			<div class="title">{{ titlec }}</div>
			<div class="close" @click="$emit('close')"><i class="fa-solid fa-xmark"></i></div>
			
			<div class="cats">
				<button v-for="(v, i) in cats" :key="i" @click="getNames(v.id)">{{ v.title }}</button>
			</div>

			<div class="content">
				<TransitionGroup name="list" :duration="500">
					<div class="vote active" v-for="(n,i) in names" :key="i" :style="{transitionDelay: i/2+'s'}" @click="setVote(n.id, i)">
						<div class="avatar" :style="{backgroundImage: 'url(https://imager.radiohabblet.com.br/?user='+n+'&action=std,crr=&gesture=std&direction=0&head_direction=2&headonly=false&size=b&img_format=&dance=&effect=&frame_num=30)'}"></div>
						<span>{{ n }}</span>
					</div>
				</TransitionGroup>
				<div class="text" v-show="names.length == 0">
					<p>Selecione uma categoria para voto!</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { base } from '../../../var.js'
import axios from 'axios'

export default {
	name: 'VotesMod',
	props: {
		titlec: {
			type: String,
			default: () => ''
		},
		id: Number
	},
	data() {
		return {
			cats: [],
			names: [],
			cat_id: 0
		}
	},
	methods: {
		async getCats(){
			const req = await axios.post(base+'content/votes/get-cat', {
				id: this.id
			})
			this.cats = req.data
		},
		async getNames(i){
			const req = await axios.post(base+'content/votes/get-names', {
				id: i
			})
			this.names = req.data.names
			this.cat_id = req.data.id
		},
		async setVote(id, c){
			const req = await axios.post(base+'content/votes/vote',{
				id: this.cat_id,
				candidate: c
			})

			this.$store.commit('Alert', req.data.text)
		}
	},
	mounted(){
		this.getCats()
	}
}
</script>

<style scoped>

.modal .content {
	height: auto;
	user-select: none;
}

.modal .close {
	position: absolute;
	right: 10px;
	top: 10px;
	width: 20px;
	height: 20px;
}

.modal .title {
	width: 100%;
	height: 50px;
	font-size: 20px;
	font-weight: 600;
	line-height: 50px;
	padding-left: 20px;
	background-color: var(--color1);
	border-radius: 8px 8px 0 0;
	color: #fff;
}

.modal .cats {
	position: absolute;
	width: 200px;
	height: auto;
	left: -50%;
	background-color: #fff;
	border-radius: 10px 0 0 10px;
}

.modal .cats button {
	width: 100%;
	height: 50px;
	font-size: 14px;
	font-weight: 600;
}

.modal .content .vote {
	width: 100%;
	height: 40px;
	line-height: 40px;
	margin-top: 4px;
	text-align: center;
	font-size: 14px;
	color: #fff;
	background-color: var(--color2);
	border-radius: 8px;
	display: flex;
	justify-content: center;
	transition: all 1s;
}

.modal .content .vote .avatar {
	width: 56px;
	height: 100%;
	background-repeat: no-repeat;
	background-position: center -40px;
	margin-right: 20px;
}

.modal .content .text p {
	text-align: center;
	width: 100%;
	height: 80px;
	line-height: 80px;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

</style>