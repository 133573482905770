<template>
    <footer>
        <div class="nav">
            <router-link :to="{path: '/', name: ''}">
                <div class="btn">Início</div>
            </router-link>
            <router-link :to="{path: '/pagina/equipe', name: 'equipe'}">
                <div class="btn">Equipe</div>
            </router-link>
            <router-link :to="{path: '/pagina/8', name:'pagina', params: {url: 'pagina', id: 8}}">
                <div class="btn">Historia</div>
            </router-link>
            <router-link :to="{path: '/pagina/imager', name: 'imager'}">
                <div class="btn">Habblet Imager</div>
            </router-link>
            <router-link :to="{path: '/pagina/valores', name: 'valores'}">
                <div class="btn">Valores</div>
            </router-link>
            <router-link :to="{path: '/pagina/recordes', name: 'recordes'}">
                <div class="btn">Recordes</div>
            </router-link>
        </div>
        <div class="about">
            <div class="center">
                <div class="logo" :style="{backgroundImage: 'url('+$store.state.initSite.logo_footer+')'}"></div>
                <div class="copy">
                    <span>Copyright © Todos os direitos reservados. Radio Habblet 2022 - {{this.date}}.</span>
                    <span>A RádioHabblet é um fã-site oficial do Habblet Hotel, não está afiliada com, patrocinada por, apoiada por, ou principalmente aprovada pela Rede Habblet. 
                        Nosso intuito é apenas dar informações e entretenimento a comunidade do hotel. 
                        Esta Fã Site pode utilizar as marcas registradas e outras propriedades intelectuais do Habblet, que estão permitidas sob a Política de Fã Sites Habblet.</span>
                </div>
                <div class="dev">Desenvolvido por <a href="#" target="_blank" title="Designer" rel="noopener noreferrer">DinhuLOL</a> e <a href="https://twitter.com/ZiniiBr" target="_blank" title="Full-stack" rel="noopener noreferrer">Zinii</a>.</div>
            </div>
            <div class="backImg"></div>
        </div>
    </footer>
</template>

<script>

export default {
    name: 'FooterItem',
    props: ['logof'],
	data() {
		return {
			date: new Date().getFullYear()
		}
	},
    mounted(){
    }
}
</script>

<style scoped>
footer {
    position: relative;
    float: left;
    width: 100%;
    height: auto;
    background-color: var(--color2);
    margin-top: 30px;
}

footer .nav {
    position: relative;
    width: 100%;
    height: 60px;
    background-color: var(--color2);
    display: flex;
    justify-content: center;
}

footer .nav .btn {
    float: left;
    width: auto;
    height: 100%;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    padding: 20px;
}

footer .about {
    position: relative;
    height: 300px;
}

footer .about .logo {
    float: left;
    width: 140px;
    height: 100px;
    margin: 20px 0 0 50%;
    transform: translateX(-50%);
    background-repeat: no-repeat;
    background-position: center;
}

footer .about .copy,
footer .about .dev {
    float: left;
    width: 100%;
    text-align: center;
    margin-top: 10px;
    color: #fff;
    font-size: 13px;
    font-weight: 500;
}
footer .about .dev {font-weight: 600;}

footer .backImg {
    position: absolute;
    right: 0;
    top: 0;
    width: 657px;
    height: 300px;
    background-image: url('../assets/headerBack.png');
    background-repeat: no-repeat;
}
</style>